<template>
	<div class="h-full flex flex-col" v-if="table">
		<TopBar :showHome="true"/>
		<AppTitle :title="tableName" v-if="table"/>
		<div class="flex space-x-6 mt-4">
			<router-link :to="{name : 'table-lobby', params: { uuid: table.uuid }}" class="font-bold text-lg text-red-500">Table</router-link>
			<router-link :to="{name : 'chat', params: { uuid: table.uuid }}" class="font-bold text-lg text-gray-300">Chat</router-link>
		</div>
		<div class="pt-4 text-gray-400 space-y-4 overflow-y-scroll">

			<!-- Waiting -->
			<div v-if="!tableDone" class="text-center mt-8">
				<div class="flex">
					<p class="flex-1 text-gray-600 font-bold">
						Waiting for others preferences
					</p>
				</div>
				<div class="flex mt-2 space-x-2 items-center justify-center">
					<div>
						<user-profile-picture 
							:user="currentUser"
							:size="12"
						/>
						<div class="mt-2 h-8 w-8 rounded-full mx-auto" :class="table.answers.status==='complete' ? 'bg-green-400' : 'bg-red-500'">
							<font-awesome-icon :icon="table.answers.status==='complete' ? 'fa-solid fa-check' : 'fa-solid fa-clock'" class="text-white align-middle mt-1"/>
						</div>
					</div>
					<div v-for="user in table.users" :key="user.uuid">
						<user-profile-picture 
							:user="user"
							:size="12"							
						/>
						<div class="mt-2 h-8 w-8 rounded-full mx-auto" :class="user.status==='complete' ? 'bg-green-400' : 'bg-red-500'">
							<font-awesome-icon :icon="user.status==='complete' ? 'fa-solid fa-check' : 'fa-solid fa-clock'" class="text-white align-middle mt-1"/>
						</div>
					</div>
				</div>
			</div>

			<!-- Done -->
			<div v-if="tableDone">
				<div class="flex text-center mb-4">
					<p class="flex-1 text-gray-600 font-bold">
						All set, here are our recommendations
					</p>
				</div>
				<div class="space-y-2">
					<div 
						class="bg-white py-5 px-5 rounded-xl border border-gray-100 cursor-pointer text-gray-500" 
						@click="$router.push({
							name: 'table-restaurant', 
							params: { 
								restaurant_uuid: restaurant.uuid,
								uuid: table.uuid
							}
						})"
						v-for="restaurant in table.restaurants"
						:key="restaurant.uuid"
					>
						<div class="flex text-red-500 font-bold">
							<span class="flex-1">{{ restaurant.name }}</span>
							<span class="text-sm">{{ restaurant.pricing }}</span>
						</div>
						<div class="flex text-sm mt-2">
							<span class="flex-1 text-gray-500 font-semibold">{{ restaurant.cuisines.map( x => x.label ).join(", ") }}</span>
							<span><font-awesome-icon icon="fa-solid fa-chevron-right"/></span>
						</div>
						<div class="flex text-sm mt-2">
							<span class="flex-1">{{ restaurant.street }}</span>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	import UserProfilePicture from '@/components/UserProfilePicture';
	export default {
		components: {
			TopBar,
			AppTitle,
			UserProfilePicture,
		},
		data(){
			return {

			}
		},
		computed: {
			tableName(){
				return this.table.name.length ? this.table.name : `Table ${this.table.code.toUpperCase()}`
			},
			tableDone(){
				return this.table.restaurants.length;
			},
			currentUser(){
				return this.$store.state.auth.user;
			},
			table() {
				return this.$store.state.table.table;
			},
		},
		methods: {

		}
	}
</script>