var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.table)?_c('div',{staticClass:"h-full flex flex-col"},[_c('TopBar',{attrs:{"showHome":true}}),(_vm.table)?_c('AppTitle',{attrs:{"title":_vm.tableName}}):_vm._e(),_c('div',{staticClass:"flex space-x-6 mt-4"},[_c('router-link',{staticClass:"font-bold text-lg text-red-500",attrs:{"to":{name : 'table-lobby', params: { uuid: _vm.table.uuid }}}},[_vm._v("Table")]),_c('router-link',{staticClass:"font-bold text-lg text-gray-300",attrs:{"to":{name : 'chat', params: { uuid: _vm.table.uuid }}}},[_vm._v("Chat")])],1),_c('div',{staticClass:"pt-4 text-gray-400 space-y-4 overflow-y-scroll"},[(!_vm.tableDone)?_c('div',{staticClass:"text-center mt-8"},[_vm._m(0),_c('div',{staticClass:"flex mt-2 space-x-2 items-center justify-center"},[_c('div',[_c('user-profile-picture',{attrs:{"user":_vm.currentUser,"size":12}}),_c('div',{staticClass:"mt-2 h-8 w-8 rounded-full mx-auto",class:_vm.table.answers.status==='complete' ? 'bg-green-400' : 'bg-red-500'},[_c('font-awesome-icon',{staticClass:"text-white align-middle mt-1",attrs:{"icon":_vm.table.answers.status==='complete' ? 'fa-solid fa-check' : 'fa-solid fa-clock'}})],1)],1),_vm._l((_vm.table.users),function(user){return _c('div',{key:user.uuid},[_c('user-profile-picture',{attrs:{"user":user,"size":12}}),_c('div',{staticClass:"mt-2 h-8 w-8 rounded-full mx-auto",class:user.status==='complete' ? 'bg-green-400' : 'bg-red-500'},[_c('font-awesome-icon',{staticClass:"text-white align-middle mt-1",attrs:{"icon":user.status==='complete' ? 'fa-solid fa-check' : 'fa-solid fa-clock'}})],1)],1)})],2)]):_vm._e(),(_vm.tableDone)?_c('div',[_vm._m(1),_c('div',{staticClass:"space-y-2"},_vm._l((_vm.table.restaurants),function(restaurant){return _c('div',{key:restaurant.uuid,staticClass:"bg-white py-5 px-5 rounded-xl border border-gray-100 cursor-pointer text-gray-500",on:{"click":function($event){return _vm.$router.push({
						name: 'table-restaurant', 
						params: { 
							restaurant_uuid: restaurant.uuid,
							uuid: _vm.table.uuid
						}
					})}}},[_c('div',{staticClass:"flex text-red-500 font-bold"},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(restaurant.name))]),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(restaurant.pricing))])]),_c('div',{staticClass:"flex text-sm mt-2"},[_c('span',{staticClass:"flex-1 text-gray-500 font-semibold"},[_vm._v(_vm._s(restaurant.cuisines.map( x => x.label ).join(", ")))]),_c('span',[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-right"}})],1)]),_c('div',{staticClass:"flex text-sm mt-2"},[_c('span',{staticClass:"flex-1"},[_vm._v(_vm._s(restaurant.street))])])])}),0)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('p',{staticClass:"flex-1 text-gray-600 font-bold"},[_vm._v(" Waiting for others preferences ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex text-center mb-4"},[_c('p',{staticClass:"flex-1 text-gray-600 font-bold"},[_vm._v(" All set, here are our recommendations ")])])
}]

export { render, staticRenderFns }